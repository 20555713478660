<template>
  <view class="body">
    <img src="@/assets/image/top_bg.jpg" class="top_img"/>
    <view style="position: relative;padding: 0;margin: 0">
      <view class="yqyl_kuang_content">
        <img src="@/assets/image/yqyl_kuang.png" class="yqyl_kuang"/>
        <view class="yqyl_kuang_content_content">
          <view class="yqyl_kuang_content_title">已邀女士(人)</view>
          <view class="yqyl_kuang_content_num" v-if="userData">{{ userData.female_num }}</view>
        </view>
        <img src="@/assets/image/yqyl_fgx.png" class="yqyl_kuang_content_fenge"/>
        <view class="yqyl_kuang_content_content_two">
          <view class="yqyl_kuang_content_title">已邀男士（人）</view>
          <view class="yqyl_kuang_content_num" v-if="userData">{{ userData.male_num }}</view>
        </view>
      </view>
      <view class="ljyl_ll">
        <view class="ljsytxt">累计收益：</view>
        <view class="ljsyjetxt" v-if="userData">￥{{ userData.invite_income }}</view>
        <img src="@/assets/image/yqyl_tx.png" class="yqyl_tx" @click="goTixian"/>
      </view>
      <view class="yq_hf_ll">
        <img src="@/assets/image/pic_yq_hf.png" class="pic_yq_hf"/>
        <view class="pic_yq_hf_content">
          <img src="@/assets/image/ic_yq_gb.png" class="ic_yq_gb"/>
          <view class="pic_yq_hf_content_text" id="pic_yq_hf_content_text">{{ nickName }}{{ operation }}，邀请人获得奖励
          </view>
          <view class="pic_yq_hf_content_text_1" id="pic_yq_hf_content_text_1">{{ shouyi }}元</view>
        </view>
        <view style="clear: both;"></view>
      </view>
    </view>
    <img src="@/assets/image/yqyl_yqjl.png" class="yqyl_yqjl"/>
    <img src="@/assets/image/yqyl_xjjl.png" class="yqyl_yqjl"/>
    <view class="yq_list_ll" v-if="userData && userData.invite_log.length>0">
      <img src="@/assets/image/yqyl_kuang_yqjl.png" class="yqyl_kuang_yqjl"/>
      <view class="yqyl_yqjl_bq_ll">
        <img src="@/assets/image/yqyl_yqjl_bq.png" class="yqyl_yqjl_bq"/>
        <view>
          <view style="position: relative">
            <img src="@/assets/image/yjqrs.png" class="yq_touxiang"/>
            <view class="yqrenshu">
              已邀请{{ userData.invite_log.length }}人
            </view>
          </view>
        </view>
        <view
            class="yq_list">
          <view class="yqyhtext">
            用户
          </view>
          <view class="yqyhtext">
            邀请时间
          </view>
        </view>
        <template v-for="itemData in userData.invite_log" :key="itemData.id">
          <view class="list_view">
            <view style="display: flex;flex-direction: row">
              <view style="position: relative">
                <img :src="itemData.icon" class="touxiang"/>
                <img :src="itemData.sex === 1?female:male" class="ic_sex"/>
              </view>
              <view class="nicheng">
                {{ itemData.nickname }}
              </view>
            </view>
            <view class="riqi">
              {{ itemData.create_time }}
            </view>
          </view>
        </template>
      </view>
    </view>
   <view class="bottom_btn" v-if="showPoster">
    <img src="@/assets/image/btn_share_yq.png" class="bottom_btn_share_yq" @click="goShare"/>
    <img src="@/assets/image/bottom_btn_share_fx.png" class="bottom_btn_share_fx" @click="goShareFx"/>
   </view>
   <img src="@/assets/image/btn_share_fx.png" class="btn_share_fx" @click="goShare" v-else />
    <img src="@/assets/image/yqyl_hdgz.png" class="btn_yqyl" @click="hdgz"/>
  </view>
</template>

<script setup>
import router from '@/router';
import axios from 'axios';
import {onMounted, ref} from 'vue';
import female from '@/assets/image/ic_female.png';
import male from '@/assets/image/ic_man.png';

const params = new URLSearchParams(window.location.search);
const uid = params.get('uid');
const showPoster = ref(false);
let hasPoster = params.has('show_poster')
showPoster.value=hasPoster
let userData = ref();
const instance = axios.create({
  baseURL: 'https://api.sy3636.com', // 设置请求的基础URL
  timeout: 5000, // 设置请求超时时间
});
const data = new URLSearchParams();
data.append('uid', uid);
onMounted(() => {
  instance.post('/api/v1/user/invite_user_index_2', data).then((respose) => {
    userData.value = respose.data.data;
  });
});
const nickName = ref('');
const shouyi = ref(0);
const operation = ref('');
setInterval(() => {
  if (userData.value === undefined) {
    return;
  }
  const fakeData = userData.value.fake_invite_data[Math.floor(Math.random() * userData.value.fake_invite_data.length)];
  console.log('fakeData.income:'+fakeData.income)
  shouyi.value = fakeData.income;
  operation.value = fakeData.operate_type;
  nickName.value = fakeData.nickname;
}, 5000);

const goShare = () => {
  window.location.href = 'sheyan://share';
};
const goShareFx = () => {
  window.location.href = 'sheyan://share_poster';
};
const goTixian = () => {
  window.location.href = 'sheyan://tixian';
};

const hdgz = () => {
  router.push('HdgzActivity');
};
</script>
<style>
.yq_list_ll {
  position: relative;
  margin-bottom: 200px;
}

.yqyl_yqjl {
  position: relative;
  width: 100vw;
}
.bottom_btn{
  width: 100vw;
  height: 210px;
  background: url('../assets/image/yqyl_zzg.png') no-repeat center / 100% 100%;;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  /* margin-bottom: 60px; */
  overscroll-behavior: contain;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  .bottom_btn_share_fx{
    width: 304px;
    height: 120px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .bottom_btn_share_yq{
    width: 304px;
    height: 120px;
    margin-left: 60px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
.btn_share_fx {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 30px;
  width: 658px;
  overscroll-behavior: contain;
  z-index: 3;
  display: block;
}

.ljyl_ll {
  display: flex;
  flex-direction: row;
  left: 0px;
  top: 0px;
  margin-top: 285px;
  margin-left: 6.5%;
  width: 93.5%;
  position: absolute
}

.yqyl_tx {
  margin-left: 65%;
  width: 190px;
  height: 74px;
  position: absolute;
}

.yqyl_kuang_yqjl {
  position: relative;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  margin-top: 10px
}

.yqyl_yqjl_bq_ll {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.yqyl_yqjl_bq {
  width: 516px;
  height: 72px;
  align-self: center;
}

.yq_hf_ll {
  position: absolute;
  display: flex;
  left: 0;
  top: 0
}

.yq_touxiang {
  margin-top: 20px;
  margin-right: 8%;
  margin-left: 8%;
  width: 84%;
  position: relative;
  display: flex
}

.yqrenshu {
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #D5471C;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  display: flex;
  height: 100%;
  padding-top: 10px;
  align-items: center
}

.yq_list {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 19px
}

.list_view {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 36px;
  overflow: auto;
  max-height: 200px; /* 可以根据需要调整 */
}

.touxiang {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%
}

.ic_sex {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-left: 38px
}

.nicheng {
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #363541;
  margin-left: 18px;
  align-self: center
}

.riqi {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #616065;
  align-self: center
}

.ic_yq_gb {
  width: 40px;
  height: 40px
}

.yqyhtext {
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #82532D;
}

.ljsyjetxt {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 36px;
  margin-left: 10px;
  align-self: center;
  margin-top: 20px;
}

.ljsytxt {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
  align-self: center;
  margin-top: 20px;
}

.yqyl_kuang_content_title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #815A5A;
  line-height: 36px;
}

.yqyl_kuang_content_num {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #DB2E33;
  line-height: 36px;
  margin-top: 40px;
  align-self: center;
}

.yqyl_kuang_content_content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 125px;
  left: 0;
  margin-left: 25%;
}

.yqyl_kuang_content_content_two {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 125px;
  left: 0;
  margin-left: 65%;
}

.yqyl_kuang_content_fenge {
  position: absolute;
  top: 150px;
  width: 2px;
  height: 49px;
  left: 0;
  margin-left: 50%;
}

.yqyl_kuang_content {
  width: 100vw;
  background-color: #F23E35;
  position: relative;
  display: flex;
  height: 422px;
}

.yqyl_kuang {
  width: 100vw;
  position: relative;
}

.body {
  background-color: #F23E35;
  margin: 0;
  flex-direction: column;
  display: flex;
  width: 100vw;
  height: auto;
  padding-bottom: 120px;
}

.top_img {
  width: 100vw;
  padding: 0;
  margin: 0;
}

.pic_yq_hf {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.pic_yq_hf_content {
  flex-direction: row;
  display: flex;
  position: absolute;
  z-index: 1;
  margin-left: 73px;
  align-self: center;
}

.pic_yq_hf_content_text {
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FEFEFF;
  line-height: 36px;
  align-self: center;
  margin-left: 10px;
}

.pic_yq_hf_content_text_1 {
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFE92E;
  align-self: center;
  line-height: 36px;
}

.btn_yqyl {
  position: absolute;
  top: 171px;
  right: 0;
  width: 106px;
  height: 55px;
}
</style>